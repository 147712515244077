@media(prefers-color-scheme: dark) {

    /* BG: Elementi con #CBCBCB */
    .menu-strip,
    .nav-bar,
    .tile-article,
    .cortana-logo,
    .search-box-cortana,
    .update-box-content,
    .article-title-container,
    .box-update-container,
    .box-update-tile-icon-text,
    .wbi-aab-tutorial-adblockers,
    .wbi-aab-tutorial-adblockers-btn,
    select {
        background-color: #343434;
        color: #FFF;
    }

    /* BG: Retro articoli (Bianco) */
    .global-container,
    .page-body,
    .article-comments,
    amp-iframe {
        background-color: #1A1A1A;
        color: #FFF;
    }

    /* BG: Elementi con #B2B2B2 e #BDBDBD*/
    .menu-strip ul li:hover,
    .full-menu,
    .top-menu-open,
    .update-box-arrow,
    .update-box-info,
    .box-update-tile-icon-text:hover,
    .article-arrow,
    .tile-article:hover {
        background-color: #4D4D4D;
    }

    /* BG: Elementi con #8A8A8A */
    .top-menu-open:hover,
    .update-box-arrow:hover,
    .article-arrow a:hover,
    .box-update-img,
    .wbi-aab-tutorial-adblockers-btn:hover {
        background-color: #757575;
    }

    /* BG: Elementi con #E5E5E5 */
    body {
        background: #111;
    }

    /* FG: Nero*/
    .menu-strip ul a,
    .menu-strip ul li,
    .article-title,
    .tile-content-ad a,
    .box-update,
    .box-update-title,
    .box-update-tile-icon-text {
        color: white;
    }

    /* Filtri */
    .top-menu-open img,
    .article-arrow img,
    .loading-posts img,
    .box-update-categoryicons {
        filter: invert(1);
    }

    blockquote {
        background: #060606;
        border-left: 10px solid #238e9a;
    }

    .article-comments {
        background: #FFF;
    }

    .home-container::after {
        content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgc3R5bGU9InNoYXBlLXJlbmRlcmluZzogYXV0bzsgZGlzcGxheTogYmxvY2s7IGJhY2tncm91bmQ6IHJnYigyNiwgMjYsIDI2KTsiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGc+PGNpcmNsZSBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHI9IjM1IiBzdHJva2Utd2lkdGg9IjEwIiBzdHJva2U9IiMyMzhlOWEiIGZpbGw9Im5vbmUiIGN5PSI1MCIgY3g9IjUwIj4KICA8YW5pbWF0ZVRyYW5zZm9ybSBrZXlUaW1lcz0iMDsxIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBkdXI9IjEuMjVzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgdHlwZT0icm90YXRlIiBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+PGc+PC9nPjwvZz48IS0tIFtsZGlvXSBnZW5lcmF0ZWQgYnkgaHR0cHM6Ly9sb2FkaW5nLmlvIC0tPjwvc3ZnPg==");
    }
}