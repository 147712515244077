.article-header {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
}

.article-arrow {
    width: 50px;
    min-height: 100px;
    overflow: hidden;
    background-color: #bdbdbd;
    height: 100%;
    position: absolute;
}

.article-arrow a:hover {
    background-color: #8a8a8a;
}

.article-title-container {
    background-color: var(--light-gray-bg-color);
    overflow: hidden;
    min-height: 100px;
    padding: 0 50px;
}

.article-title-container h1 {
    margin: 7px 0 0 10px;
    min-height: 65px;
    font-weight: 300;
    line-height: 28px;
    font-size: 23px;
}

.article-title-container p {
    margin-right: 10px;
    margin-top: 2px;
    font-size: 17px;
    font-weight: 100;
    float: right;
    margin-bottom: 5px;
}

.article-wrapper {
    margin-left: 10px;
    margin-right: 10px;
}

.article-arrow img {
    width: 25px;
    height: 25px;
    position: absolute;
    display: block;
    margin: auto;
    margin-left: 12.5px;
    top: 0;
    bottom: 0;
}

.article-arrow a {
    display: block;
    min-height: 100px;
    height: 100%;
}

.article-nobutton-next {
    margin-left: 0;
}

.article-nobutton-previous {
    margin-right: 0;
}

.article-highlight {
    text-decoration: none;
    color: var(--accent-color);
}

.article-body {
    max-width: 1230px;
    margin: 1em auto;
}

.article-body .tile-list {
    margin-left: -10px !important;
}

.article-body .tile-list .home-container {
    overflow: hidden !important;
}

.article-body p,
.article-body li,
.article-body {
    font-size: 18px;
    line-height: 1.6;
    font-weight: normal;
    text-align: justify;
}

.article-body h1 {
    font-size: 34px;
    font-weight: normal;
    text-align: left;
    margin-top: 60px;
    margin-bottom: 10px;
}

.article-body h2 {
    font-size: 30px;
    font-weight: normal;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 10px;
}

.article-body h3 {
    font-size: 26px;
    font-weight: normal;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 10px;
}

.article-body h4 {
    font-size: 22px;
    font-weight: normal;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 10px;
}

.article-body strong {
    font-weight: bold;
}

.article-body em {
    font-style: italic;
}

.article-body ul {
    list-style: disc;
    padding: 0 0 0 40px;
}

.article-social-buttons-container {
    display: flex;
    gap: 10px;
    font-size: 20px;
}

.article-social-buttons-container a {
    display: block;
    padding: 10px;
    width: 50px;
    text-align: center;
    text-decoration: none;
    color: white;
}

.article-comments {
    margin: 1em 0;
}

.fb-page {
    display: block !important;
    text-align: center !important;
}

blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C" "\201D" "\2018" "\2019";
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

blockquote p:first-child {
    display: inline;
}

blockquote p:last-child {
    margin-bottom: 5px;
}

.article-body h1+h2,
.article-body h2+h3,
.article-body h3+h4 {
    margin-top: 10px;
}

.article-body h1+h1,
.article-body h2+h2,
.article-body h3+h3,
.article-body h4+h4 {
    margin-top: -10px;
}

.article-ad-container {
    margin: 20px 10px;
}

.article-share-buttons {
    margin: 20px 0;
}

@media screen and (min-width: 1290px) {

    .article-body>p.p-text-only,
    .article-body>h1,
    .article-body>h2,
    .article-body>h3,
    .article-body>h4,
    .article-body>h5,
    .article-body>h6,
    .article-body>ul,
    .article-body>ol,
    .article-body>blockquote {
        width: 70%;
        margin-left: 15%;
    }
}

.article-body amp-img {
    max-height: 90vh;
}

.article-body amp-carousel {
    max-height: 90vh;
}

.article-body amp-carousel amp-img,
.article-body amp-carousel amp-img img {
    object-fit: contain;
}