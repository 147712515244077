@media screen and (max-width: 520px) {
    body {
        margin: 0;
    }

    .page-body {
        width: 100%;
    }

    .header {
        height: 140px;
        position: relative;
    }

    .wbi-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0;
        width: 80%;
        height: auto;
    }

    .sidebar-container {
        display: none;
    }

    .main-menu {
        display: inline-block;
        width: calc(100% - 120px);
        white-space: nowrap;
        overflow: auto;
        position: relative;
        top: 0px;
        transition: top 0.5s;
    }

    .main-menu-closed {
        top: 50px !important;
    }

    .main-container {
        width: 100%;
        padding: 10px;
        padding-bottom: 0;
        box-sizing: border-box;
        margin: 0;
    }

    .home-container {
        width: 100%;
        margin-left: 0;
    }

    /*Header*/

    .cortana-icon-mobilebox {
        width: 50px;
        height: 50px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
    }

    #cortana-icon-mobile {
        width: 35px;
        height: 35px;
        margin-top: 7.5px;
        margin-left: 7.5px;
    }

    #search-input-mobile-box {
        width: calc(100% - 120px);
        height: 50px;
        left: 120px;
        box-sizing: border-box;
        position: absolute;
        top: -48px;
        background-color: #cbcbcb;
        transition: top 0.5s;
        display: block !important;
    }

    .search-input-open {
        top: 0px !important;
    }

    .search_container {
        width: auto;
    }

    .search-box-cortana {
        width: 100%;
        box-sizing: border-box;
        padding-right: 10px;
        font-size: 16px;
        outline: none;
    }

    .menu-strip ul a {
        padding: 0px 10px;
    }

    .menu-strip ul li {
        font-size: 16px;
    }

    /*Tile list*/

    .tile {
        margin: 0;
        margin-top: 5vw;
    }

    .tile:nth-of-type(1) {
        margin-top: 0;
    }

    .tile-article,
    .article-sticky {
        width: 100%;
        height: initial;
    }

    .article-image {
        width: 100%;
        height: initial !important;
        display: initial;
    }

    .article-image amp-img img {
        width: 100%;
        height: initial;
    }

    .article-title-box {
        height: auto;
        padding: 10px;
        margin-top: -4px;
        box-sizing: border-box;
        width: 100%;
        font-size: 21px;
    }

    /*Articolo*/

    .corrispondenza {
        display: none;
    }

    .article-wrapper {
        margin: 0;
    }

    .article-title-container {
        margin: 0;
        padding: 10px;
        min-height: 80px;
    }

    .article-title-container h1 {
        margin: 0;
        font-size: 21px;
        line-height: 25px;
    }

    .article-title-container p {
        margin: 0;
        margin-top: 10px;
        position: initial;
        font-size: 15px;
        text-align: right;
    }

    .article_score {
        display: none;
    }

    .article_time {
        display: none;
    }

    .article_time_mobile {
        display: block;
    }

    #article-mobile-arrows {
        width: 100%;
        height: 40px;
        display: block !important;
        background-color: #bdbdbd;
    }

    .article-mobile-arrow {
        width: 50%;
        height: 40px;
        float: left;
        background-color: #bdbdbd;
        text-align: center;
    }

    .mobile-arrow-right {
        float: right;
        width: calc(50% - 1px);
        border-left: 1px solid #8a8a8a;
    }

    .mobile-arrow-left {
        float: left;
    }

    .article-mobile-arrow:hover {
        background-color: #8a8a8a;
    }

    .article-mobile-arrow img {
        height: 24px;
        margin-top: 8px;
    }

    .article-arrow {
        display: none;
    }

    /*Search header*/

    .search-header {
        height: initial;
        margin-bottom: 50px;
    }

    .cortana-logo-searchpage {
        margin-top: 0px;
    }

    .cortana-query {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 950px) and (min-width: 521px) {
    body {
        margin: 0;
    }

    .page-body {
        width: 100%;
    }

    .header {
        height: 140px;
        position: relative;
    }

    .sidebar-container {
        display: none;
    }

    .main-container {
        width: 100%;
        padding: 10px;
        padding-bottom: 0;
        box-sizing: border-box;
        margin: 0;
    }

    .home-container {
        width: 100%;
        margin-left: 0;
    }

    .cortana-icon-mobilebox {
        width: 50px;
        height: 50px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
    }

    #cortana-icon-mobile {
        width: 35px;
        height: 35px;
        margin-top: 7.5px;
        margin-left: 7.5px;
    }

    #search-input-mobile-box {
        width: calc(100% - 120px);
        height: 50px;
        left: 120px;
        box-sizing: border-box;
        position: absolute;
        top: -48px;
        background-color: #cbcbcb;
        transition: top 0.5s;
        display: block !important;
    }

    .search-input-open {
        top: 0px !important;
    }

    .search_container {
        width: auto;
    }

    .search-box-cortana {
        width: 100%;
        box-sizing: border-box;
        padding-right: 10px;
        font-size: 16px;
        outline: none;
    }

    .menu-primario-container {
        display: inline-block;
        width: calc(100% - 120px);
        white-space: nowrap;
        overflow: auto;
        position: relative;
        top: 0px;
        transition: top 0.5s;
    }

    .menu-primario-closed {
        top: 50px !important;
    }

    .menu-strip ul a {
        padding: 0px 10px;
    }

    .menu-strip ul li {
        font-size: 16px;
    }

    .tile {
        margin: 0;
        margin-bottom: 25px;
    }

    .tile:nth-of-type(even) {
        margin-left: 25px;
    }

    .tile-article {
        width: calc(50% - 12.5px);
        height: initial;
    }

    .article-sticky {
        width: 100%;
        height: auto;
    }

    .article-image {
        width: 100%;
        height: initial !important;
        display: initial;
    }

    .article-image img {
        width: 100%;
        height: initial;
    }

    .article-title-box {
        height: auto;
        padding: 10px;
        margin-top: -4px;
        box-sizing: border-box;
        width: 100%;
        font-size: 21px;
    }
}