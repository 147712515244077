:root {
    --accent-color: #238e9a;
    --light-gray-bg-color: #cbcbcb;
    --body-bg-color: #e5e5e5;
}

body {
    font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
        "Helvetica Neue", Arial, sans-serif;
    background-color: var(--body-bg-color);
    margin: 0;
}

a,
a:visited {
    color: var(--accent-color);
    text-decoration: none;
}

a:hover {
    color: #8a8a8a;
    text-decoration: none;
}

.cortana-icon-mobilebox,
#search-input-mobile-box,
.article_time_mobile {
    display: none;
}

.grecaptcha-badge {
    display: none;
}