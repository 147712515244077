.global-container {
    min-height: 100%;
    position: relative;
    z-index: 20;
    background-color: white;
    overflow: hidden;
    display: flex;
}

.home-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    overflow-anchor: none;
}

.tile-list {
    min-width: 620px;
    margin-left: 10px;
}

.tile-list div[role="list"] {
    min-width: 620px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.home-container::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgc3R5bGU9InNoYXBlLXJlbmRlcmluZzogYXV0bzsgZGlzcGxheTogYmxvY2s7IGJhY2tncm91bmQ6IHJnYigyNTUsIDI1NSwgMjU1KTsiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGc+PGNpcmNsZSBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHI9IjM1IiBzdHJva2Utd2lkdGg9IjEwIiBzdHJva2U9IiMyMzhlOWEiIGZpbGw9Im5vbmUiIGN5PSI1MCIgY3g9IjUwIj4KICA8YW5pbWF0ZVRyYW5zZm9ybSBrZXlUaW1lcz0iMDsxIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBkdXI9IjEuMjVzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgdHlwZT0icm90YXRlIiBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iPjwvYW5pbWF0ZVRyYW5zZm9ybT4KPC9jaXJjbGU+PGc+PC9nPjwvZz48IS0tIFtsZGlvXSBnZW5lcmF0ZWQgYnkgaHR0cHM6Ly9sb2FkaW5nLmlvIC0tPjwvc3ZnPg==");
    padding: 10px;
    text-align: center;
    width: 100%;
    padding: 20px;
}

.tile-list-sidebar {
    margin-bottom: 6px;
}

.tile-list-sidebar div[role="list"] div.tile:last-child {
    margin-bottom: 0;
}

.sidebar-container {
    width: 310px;
    float: right;
}

.page-body {
    width: 940px;
    margin: auto;
    background-color: white;
    overflow: hidden;
}

.main-container {
    width: 630px;
    margin-top: 10px;
    right: 320px;
}

.loading-posts {
    text-align: center;
}

@media screen and (max-width: 950px) {
    .tile-list {
        min-width: 100%;
        margin-left: 0;
    }

    .tile-list div[role="list"] {
        min-width: 100%;
    }

    .page-body {
        width: 100%;
    }

    .main-container {
        width: 100%;
    }
}

@media screen and (max-width: 950px) and (min-width: 521px) {
    .tile-list {
        min-width: 100%;
        margin-left: 0;
    }

    .tile-list div[role="list"] {
        min-width: 100%;
    }

    .page-body {
        width: 100%;
    }

    .main-container {
        width: 100%;
    }
}

@media screen and (min-width: 1290px) {
    .tile-list {
        min-width: 930px;
    }

    .tile-list div[role="list"] {
        min-width: 930px;
    }

    .page-body {
        width: 1250px;
    }

    .main-container {
        width: 940px;
    }
}

@media screen and (min-width: 1600px) {
    .tile-list {
        min-width: 1240px;
    }

    .tile-list div[role="list"] {
        min-width: 1240px;
    }

    .page-body {
        width: 1560px;
    }

    .main-container {
        width: 1250px;
    }
}

.filter-header {
    display: block;
    margin: 10px;
    font-size: 20pt;
    font-weight: 300;
    margin-bottom: 20px;
}

.search-header {
    padding: 0 10px;
    height: 310px;
}

.filter-header strong {
    font-weight: 600;
}

.cortana-logo-searchpage {
    display: block;
    margin: auto;
}

.cortana-query {
    text-align: center;
    display: block;
    margin-bottom: 20px;
    margin-top: 10px;
    font-size: 20pt;
    color: var(--accent-color);
    font-weight: 300;
}

.cortana-tip {
    text-align: center;
    display: block;
    font-size: 17px;
    color: #8a8a8a;
}

.article-img {
    position: relative;
    height: 145px;
}

.article-img img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.article-title-box {
    width: 280px;
    height: 135px;
    padding: 6px 10px 10px 10px;
    font-size: 23px;
    font-weight: 300;
    line-height: 28px;
}

.article-title {
    height: 115px;
    color: black;
    display: block;
    overflow: hidden;
    text-align: left;
}

.article-info {
    width: 300px;
    font-size: 17px;
    font-weight: 100;
    color: var(--accent-color);
}

.article-sticky {
    background-color: var(--accent-color);
    color: white;
    height: 300px;
    width: 300px;
}

.article-sticky span,
.article-sticky a,
.article-sticky a:visited,
.article-sticky a:hover,
.article-sticky .article-info {
    color: white;
}

.article-date,
.article-date div {
    display: inline;
}