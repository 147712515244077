iframe[src*="ads-iframe"] {
    display: none !important;
    max-height: 0 !important;
}

@media (prefers-color-scheme: dark) {
    #disqus_thread {
        background-color: #1A1A1A;
        color: #FFF;
    }
}