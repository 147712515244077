.header {
    background-color: var(--accent-color);
    height: 145px;
}

.header a {
    display: block;
    height: 100%;
}

.wbi-logo {
    width: 360px;
    height: 59px;
    padding: 44px 0px 0px 50px;
    float: left;
}

.nav-bar {
    height: 50px;
    background-color: var(--light-gray-bg-color);
}

.nav-bar ul {
    list-style: none;
    float: left;
    margin: 0;
    padding: 0;
}

.main-menu ul {
    overflow-x: scroll;
}

.menu-strip ul a {
    text-decoration: none;
    color: black;
    display: block;
    padding: 0 25px;
}

.menu-strip ul li {
    display: inline-block;
    font-size: 19px;
    color: black;
    line-height: 50px;
    font-weight: 400;
    transition: 0.2s ease;
}

.menu-strip ul li:hover {
    background-color: #b2b2b2;
}

.button-categories,
.button-dashboard {
    width: 50px;
    height: 50px;
    padding: 0px;
    float: left;
}

.menu-strip {
    overflow: hidden;
    background-color: var(--light-gray-bg-color);
    position: relative;
    z-index: 20;
    display: flex;
}

.full-menu {
    width: 100%;
    height: 140px;
    z-index: 10;
    position: relative;
    background-color: #b2b2b2;
    bottom: 50px;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
}

.full-menu-open {
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.full-menu-close {
    -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}

.full-menu-content {
    padding: 10px;
}

.full-menu-content h3 {
    margin-block-start: 0;
    margin-block-end: 0.5em;
}

.full-menu-content h4 {
    margin-block-start: 0;
    margin-block-end: 0.5em;
}

.top-menu-open {
    background-color: #b2b2b2;
    width: 50px;
    height: 50px;
    float: left;
    cursor: pointer;
}

.dashboard-open {
    background-color: #005b67;
    width: 50px;
    height: 50px;
    float: left;
    cursor: pointer;
}

.top-menu-open:hover:not(:focus) {
    background-color: #8a8a8a;
    cursor: hand;
}

.admin-edit-link {
    display: none !important;
}