.tile-top>div:nth-child(2)>p:nth-child(1)>a:nth-child(1)>img:nth-child(1) {
    margin-top: -16px;
}

.tile {
    width: 300px;
    height: 300px;
    margin: 0 10px 10px 0;
    background-color: var(--accent-color);
}

.tile a {
    text-decoration: none;
}

.tile-top {
    margin-top: 10px;
}

.tile-article {
    transition: 0.2s ease;
    background-color: var(--light-gray-bg-color);
}

.tile-article:hover {
    background-color: #b2b2b2;
}

.tile-header {
    width: 300px;
    height: 50px;
    position: relative;
}

.tile-content {
    width: 300px;
    height: 250px;
}

.cortana-logo {
    width: 30px;
    height: 30px;
    padding: 10px;
    float: left;
    background-color: var(--light-gray-bg-color);
}

.search-box-cortana {
    width: 250px;
    height: 50px;
    padding: 0;
    float: left;
    background-color: var(--light-gray-bg-color);
    border: 0px;
    font-size: 19px;
    color: #666666;
}

input {
    border-radius: 0px;
}

.tile-ad {
    width: 300px;
    height: 50px;
    margin: 5px 10px 10px 0;
    background-color: var(--accent-color);
    float: left;
}

.tile-header-ad {
    width: 300px;
    height: 50px;
    position: relative;
}

.tile-content-ad {
    width: 300px;
    height: 50px;
    left: 10px;
}

.tile-content-ad a {
    color: black !important;
    margin-left: 15px;
    text-decoration: underline;
}

.update-box {
    width: 300px;
    height: 300px;
    float: left;
}

.update-box-title {
    background-color: var(--accent-color);
    height: 40px;
}

.update-box-title img {
    width: 40px;
    height: 40px;
    float: left;
    background-color: #1a6b74;
}

.update-box-title span {
    float: left;
    width: 210px;
    height: 40px;
    margin-left: 10px;
    margin-right: 40px;
    color: white;
    font-size: 17px;
    padding-top: 8px;
}

.ad-box-title span {
    float: left;
    width: 210px;
    height: 40px;
    margin-left: 15px;
    margin-right: 40px;
    color: white;
    font-size: 17px;
    padding-top: 14px;
}

.update-box-content {
    padding: 10px;
    width: 280px;
    height: 240px;
    background-color: var(--light-gray-bg-color);
}

.update-box-row {
    width: 280px;
    height: 115px;
    margin-bottom: 10px;
}

.update-box-item {
    width: 75px;
    margin-right: 10px;
    float: left;
}

.update-box-arrow {
    display: block;
    width: 25px;
    margin-right: 0;
    height: 115px;
    background-color: #b2b2b2;
}

.update-box-arrow:hover {
    background-color: #8a8a8a;
}

.update-box-arrow img {
    margin-top: 45px;
    width: 25px;
    height: 25px;
}

.update-box-info {
    width: 75px;
    height: 40px;
    background-color: #b2b2b2;
    float: left;
}

.update-box-info span {
    font-size: 8pt;
    margin-left: 5px;
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    line-height: 11px;
}

.update-box-info span:first-child {
    margin-top: 4px;
}

.update-box-info span:nth-child(2) {
    color: #666666;
    margin-top: 1px;
}

.update-box-info span:nth-child(3) {
    color: #666666;
}

.update-box-app-logo {
    width: 75px;
    height: 75px;
    float: left;
}

.box-update {
    background: var(--accent-color);
    width: 300px;
    height: 300px;
    color: black;
    position: relative;
    margin: 5px;
    margin-bottom: 10px;
    margin-left: 0px;
    font-size: 0;
}

.box-update-container {
    clear: both;
    background: var(--light-gray-bg-color);
    height: 75px;
    max-height: 75px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    overflow: hidden;
    display: inline-block;
}

.box-update-img {
    width: 75px;
    height: 75px;
    float: left;
    background: #8a8a8a;
}

.box-update-img img {
    width: 75px;
    height: 75px;
    object-fit: cover;
}

.box-update-title {
    height: 55px;
    float: left;
    margin: 10px;
    width: 185px;
    color: black;
    overflow: hidden;
    font-size: 15px;
    line-height: 18px;

    max-height: 36px;
    margin-top: 0px;
}

.box-update-categoryicons {
    height: 18px;
    width: 185px;
    float: left;
    margin: 10px;
    margin-bottom: 0px;
}

.box-update-categoryicon {
    width: 18px;
    height: 18px;
    float: left;
}

.box-update-tile-icon-text {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    color: black;
    text-align: center;
    height: 25px;
    line-height: 25px;
    background: var(--light-gray-bg-color);
    transition: background-color 0.2s ease;
}

.box-update-tile-icon-text:hover {
    background: #b2b2b2;
}

/**********************************************************\
 * CSS Live Tile 3D *
\**********************************************************/

.sidebar a:hover {
    color: white;
}

.sidebar a:link {
    color: white;
}

.sidebar a:visited {
    color: white;
}

.box-3d {
    position: relative;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    float: left;
    margin: 5px;
    width: 145px;
    height: 145px;
}

.box-3d div {
    position: absolute;
    width: 134.21px;
    height: 134.21px;
    transform-origin: 50% 50% -67px;
}

.box-3d-large {
    position: relative;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    float: left;
    margin: 5px;
    width: 300px;
    height: 145px;
}

.box-3d-large div {
    position: absolute;
    width: 277.8px;
    height: 134.21px;
    margin-left: 6px;
    transform-origin: 50% 50% -67px;
}

.tile-image {
    width: 100%;
    height: 100%;
}

.clear-left {
    clear: left;
}

.tile-face-1:hover,
.tile-face-2:hover {
    box-shadow: 0px 0px 0px 2px gray;
}

.tile-face-1:active,
.tile-face-2:active {
    box-shadow: 0px 0px 0px 0px gray;
    border: 2px solid white;
}

.tile-face-1 {
    background: var(--accent-color);
    transform: perspective(900px) rotateX(0deg);
    -webkit-transform: perspective(900px) rotateX(0deg);
    animation: spin1 40s infinite ease;
    z-index: 200;
}

.tile-face-2 {
    background: var(--accent-color);
    transform: perspective(900px) rotateX(90deg);
    -webkit-transform: perspective(900px) rotateX(90deg);
    animation: spin2 40s infinite ease;
    z-index: 100;
}

/* for Safari and Chrome */
/* for IE10 and Firefox */

@keyframes spin1 {
    0% {
        transform: perspective(900px) rotateX(0deg);
        -webkit-transform: perspective(900px) rotateX(0deg);
        z-index: 200;
    }

    24% {
        transform: perspective(900px) rotateX(0deg);
        -webkit-transform: perspective(900px) rotateX(0deg);
        z-index: 200;
        filter: brightness(100%);
        -webkit-filter: brightness(1);
        -ms-filter: brightness(100%);
        -moz-filter: brightness(100%);
    }

    25% {
        transform: perspective(900px) rotateX(-90deg);
        -webkit-transform: perspective(900px) rotateX(-90deg);
        z-index: 100;
        filter: brightness(30%);
        -webkit-filter: brightness(0.3);
        -ms-filter: brightness(30%);
        -moz-filter: brightness(30%);
    }

    26% {
        transform: perspective(900px) rotateX(-270deg) scale(0, 0);
        -webkit-transform: perspective(900px) rotateX(-270deg) scale(0, 0);
        z-index: 100;
        filter: brightness(100%);
        -webkit-filter: brightness(1);
        -ms-filter: brightness(100%);
        -moz-filter: brightness(100%);
    }

    49% {
        transform: perspective(900px) rotateX(-270deg);
        -webkit-transform: perspective(900px) rotateX(-270deg);
        z-index: 100;
    }

    50% {
        transform: perspective(900px) rotateX(-360deg);
        -webkit-transform: perspective(900px) rotateX(-360deg);
        z-index: 200;
    }

    74% {
        transform: perspective(900px) rotateX(-360deg);
        -webkit-transform: perspective(900px) rotateX(-360deg);
        z-index: 200;
        filter: brightness(100%);
        -webkit-filter: brightness(1);
        -ms-filter: brightness(100%);
        -moz-filter: brightness(100%);
    }

    75% {
        transform: perspective(900px) rotateX(-450deg);
        -webkit-transform: perspective(900px) rotateX(-450deg);
        z-index: 100;
        filter: brightness(30%);
        -webkit-filter: brightness(0.3);
        -ms-filter: brightness(30%);
        -moz-filter: brightness(30%);
    }

    76% {
        transform: perspective(900px) rotateX(-630deg) scale(0, 0);
        -webkit-transform: perspective(900px) rotateX(-630deg) scale(0, 0);
        z-index: 100;
        filter: brightness(100%);
        -webkit-filter: brightness(1);
        -ms-filter: brightness(100%);
        -moz-filter: brightness(100%);
    }

    99% {
        transform: perspective(900px) rotateX(-630deg);
        -webkit-transform: perspective(900px) rotateX(-630deg);
        z-index: 100;
    }

    100% {
        transform: perspective(900px) rotateX(-720deg);
        -webkit-transform: perspective(900px) rotateX(-720deg);
        z-index: 200;
    }
}

@keyframes spin2 {
    0% {
        transform: perspective(900px) rotateX(90deg);
        z-index: 100;
    }

    24% {
        transform: perspective(900px) rotateX(90deg);
        z-index: 100;
    }

    25% {
        transform: perspective(900px) rotateX(0deg);
        z-index: 200;
    }

    49% {
        transform: perspective(900px) rotateX(0deg);
        z-index: 200;
        filter: brightness(100%);
        -webkit-filter: brightness(1);
        -ms-filter: brightness(100%);
        -moz-filter: brightness(100%);
    }

    50% {
        transform: perspective(900px) rotateX(-90deg);
        z-index: 100;
        filter: brightness(30%);
        -webkit-filter: brightness(0.3);
        -ms-filter: brightness(30%);
        -moz-filter: brightness(30%);
    }

    51% {
        transform: perspective(900px) rotateX(-270deg) scale(0, 0);
        z-index: 100;
        filter: brightness(100%);
        -webkit-filter: brightness(1);
        -ms-filter: brightness(100%);
        -moz-filter: brightness(100%);
    }

    74% {
        transform: perspective(900px) rotateX(-270deg);
        z-index: 100;
    }

    75% {
        transform: perspective(900px) rotateX(-360deg);
        z-index: 200;
    }

    99% {
        transform: perspective(900px) rotateX(-360deg);
        z-index: 200;
        filter: brightness(100%);
        -webkit-filter: brightness(1);
        -ms-filter: brightness(100%);
        -moz-filter: brightness(100%);
    }

    100% {
        transform: perspective(900px) rotateX(-450deg);
        z-index: 100;
        filter: brightness(30%);
        -webkit-filter: brightness(0.3);
        -ms-filter: brightness(30%);
        -moz-filter: brightness(30%);
    }
}

.tile-animated-text {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    overflow: hidden;
    display: block;
    max-height: 120px;
    line-height: 20px;
}

.tile-icon-text {
    position: absolute;
    font-size: 12px;
    bottom: 10px;
    left: 10px;
    color: white;
}

.sidebar-tile {
    position: relative;
    float: left;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 10px;
    margin-right: 10px;
    overflow: hidden;
    background: var(--accent-color);
    transition: box-shadow 0.3s, transform 0.3s;
}

.sidebar-tile:active {
    transform: scale(0.95, 0.95);
}

/* Dimensioni delle tile */
.sidebar-tile-medium {
    width: 145px;
    height: 145px;
}

.sidebar-tile-wide {
    width: 300px;
    height: 145px;
}

.sidebar-tile-small {
    width: 67.5px;
    height: 67.5px;
}

/* Tipo contenitori e contenuto delle tile */
.sidebar-tile-face-image {
    position: absolute;
}

.sidebar-tile-face {
    position: relative;
}

.sidebar-tile-face-fulltext {
    position: absolute;
    left: 10px;
    top: 10px;
    right: 10px;
    bottom: 10px;
}

.sidebar-tile-bottom-left-text {
    position: absolute;
    font-size: 12px;
    bottom: 10px;
    left: 10px;
}

.sidebar-tile-bottom-right-icon {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 35px;
    height: 35px;
}

.sidebar-tile-hover-border {
    position: absolute;
    top: 0;
}

/* Tile di tipo peek */
.sidebar-tile-peek {
    height: 290px;
    transition: transform 1s;
    color: white;
    animation: sidebar-tile-peek-animation 20s infinite ease;
    position: relative;
}

@keyframes sidebar-tile-peek-animation {
    0% {
        transform: translate(0px, 0px);
    }

    10% {
        transform: translate(0px, -145px);
    }

    60% {
        transform: translate(0px, -145px);
    }

    70% {
        transform: translate(0px, 0px);
    }
}

/* Tile slideshow */
.sidebar-tile-slideshow>.sidebar-tile-face-image {
    animation: sidebar-tile-slideshow-animation 70s infinite ease;
    opacity: 1;
    transform: scale(1.5, 1.5);
}

@keyframes sidebar-tile-slideshow-animation {
    0% {
        opacity: 1;
        transform: scale(1.5, 1.5);
    }

    17.143% {
        opacity: 1;
        transform: scale(1.5, 1.5);
    }

    20% {
        opacity: 0;
        transform: scale(2, 2);
    }

    97.14% {
        opacity: 0;
        transform: scale(1, 1);
    }

    100% {
        opacity: 1;
        transform: scale(1.5, 1.5);
    }
}