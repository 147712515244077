.wpcf7-form {
    width: 70%;
    margin-left: 15%;
}

.wpcf7-form input[type="text"],
.wpcf7-form input[type="email"],
.wpcf7-form input[type="url"],
.wpcf7-form input[type="password"],
.wpcf7-form input[type="search"],
.wpcf7-form input[type="number"],
.wpcf7-form input[type="tel"],
.wpcf7-form textarea {
    color: #1f252b;
    width: 100%;
    padding: 12px 16px;
    border-radius: 0;
    border: 1px solid #238e9a;
}

.wpcf7-form p {
    margin: 0 0 28px;
}

.wpcf7-text:focus,
.wpcf7-textarea:focus {
    outline: 1px solid rgba(84, 222, 197, 0.9);
    outline-offset: 0;
}

.wpcf7-form .button {
    background-color: #238e9a;
    border: 0;
    color: #fff;
    border-radius: 1px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
    width: auto;
    padding: 20px 42px;
    letter-spacing: 2px;
    font-size: 14px;
}

.wpcf7-form .button:hover {
    cursor: pointer;
    box-shadow: 0px 7px 16px -7px rgba(0, 0, 0, 0.4);
    border: 0;
}

div.wpcf7-mail-sent-ok {
    border: 0;
    background: #5471de;
    color: #fff;
    padding: 18px;
}

div.wpcf7-acceptance-missing,
div.wpcf7-validation-errors {
    border: 0;
    background: #f9443b;
    color: #fff;
    padding: 18px;
}

span.wpcf7-not-valid-tip {
    color: #f9443b;
}