/*
Theme Name: windowsblogitalia
Version: 1.0
*/

@import "_global.css";
@import "_header.css";
@import "_tiles.css";
@import "_sidebar.css";
@import "_article.css";
@import "_mobile.css";
@import "_contactform.css";
@import "_disqus.css";

/************************************************************\
 * Utility *
\************************************************************/

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.position-left {
    left: 0;
}

.position-right {
    right: 0;
}

img.aligncenter {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em auto;
}

.twitter-tweet {
    margin: 0 auto;
}

.embed-youtube,
.embed-responsive {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    margin-top: 20px;
}

.embed-youtube iframe,
.embed-responsive iframe,
.embed-youtube object,
.embed-responsive object,
.embed-youtube embed,
.embed-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.wp-embedded-content {
    margin: 0 auto;
    display: block;
}

/**********************************************************\
 * Select/Option *
\**********************************************************/
select {
    padding: 5px 10px;
    border: none;
    border-radius: 2px;
    color: #000;
    background: var(--light-gray-bg-color);
}

select:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

amp-social-share[type="telegram"] {
    background: #0088cc;
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fab' data-icon='telegram-plane' class='svg-inline--fa fa-telegram-plane fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23fff' d='M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80% 80%;
}

.consentPopup {
    background: #d3005e;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 10px;
    padding: 10px 30px;
    display: flex;
}

.consentPopup p {
    color: white;
    font-size: 1em;
    line-height: 1em;
    flex: 1;
}

.consentPopup a {
    color: white;
    text-decoration: underline;
}

.consentPopup button {
    background-color: #9d0046;
    border: 0;
    padding: 10px 30px;
    color: white;
    font-size: 1em;
    margin-right: 50px;
    float: right;
    cursor: pointer;
}


.consentPopup button.removed {
    background-color: #9d0046;
    border: 0;
    padding: 10px 30px;
    color: white;
    font-size: 1em;
    margin-right: 50px;
    float: right;
    cursor: pointer;
}

.popupOverlay {
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.7);
    padding: 5% 0;
}

.dismiss-button {
    position: absolute;
    cursor: pointer;
}

#post-consent-ui {
    display: flex;
    justify-content: center;
}

@import "_darktheme.css";